import { getHours, subDays } from "date-fns";

export function formatDate(date: Date): string {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getTargetDate(date: Date = new Date()): Date {
    if (getHours(date) < 9) {
        return subDays(date, 1)
    } else {
        return date
    }
}

export function parseDateString(dateString: string | null | undefined): Date {
    if (dateString === undefined || dateString === null) {
        return new Date()
    } else {
        try {
            return new Date(dateString)
        } catch (ignore) {
            return new Date()
        }
    }
}
