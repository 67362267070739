import { formatDate } from "../utils/dates.tsx";

export interface StockPrice {
    key: string;
    ticker: string;
    price: number;
    targetPrice: number;
    percentage: number;
}

export interface StockDetailResponse {
    name: string;
    ticker: string;
    industries: string[];
    trend: {
        duration: string;
        prices: number[];
        targetPrices: number[];
        events: any[];
    }
    user: {
        watch: boolean;
    }
}

// TODO: Sanitize the responses
export class StockApiClient {
    // Stock watchlist
    async getStockWatchlist(date: Date): Promise<StockPrice[]> {
        const dateString = formatDate(date)
        const response = await fetch(`/api/stocks/watchlist?date=${dateString}`)
        const { stocks } = await response.json()
        return stocks
    }

    async getStockInWatchlist(ticker: string): Promise<StockDetailResponse> {
        const response = await fetch(`/api/stocks/watchlist/${ticker.toUpperCase()}`)
        return await response.json()
    }

    async removeStockFromWatchlist(ticker: string) {
        await fetch(`/api/stocks/watchlist/${ticker}`, { method: 'DELETE' })
    }

    async addStockToWatchlist(ticker: string) {
        await fetch(`/api/stocks/watchlist/${ticker}`, { method: 'POST' })
    }

    // Available stocks
    async searchAvailableStocks(query: string): Promise<{ tickers: string[] }> {
        const response = await fetch(`/api/stocks/available?q=${encodeURIComponent(query)}`)
        return (await response.json())
    }
}

export default new StockApiClient()
