import React from "react";
import { Button, Space } from "antd";
import DebounceSelect from "../../components/DebounceSelect.tsx";
import stockApiClient from "../../apiclients/StockApiClient.ts";

interface SelectOption {
    label: string;
    value: string;
}

export default function AddStockButton() {
    const [stock, setStock] = React.useState<SelectOption>();

    return (
        <Space.Compact block style={{ width: '300px' }}>
            <DebounceSelect
                value={stock}
                placeholder="Select tickers"
                fetchOptions={async (query: string) => {
                    if (query.length === 0) return []
                    return (await stockApiClient.searchAvailableStocks(query.toUpperCase()))
                        .tickers
                        .sort()
                        .map((ticker) => ({ label: ticker, value: ticker }))
                }}
                onChange={(newValue) => {
                    setStock(newValue as SelectOption);
                }}
                debounceTimeout={500}
                style={{ width: '100%' }}
            />
            <Button
                style={{ height: 'inherit' }}
                href={`/stocks/${stock?.value}`}
                disabled={!stock}
            >
                GO
            </Button>
        </Space.Compact>
    )
}
