import React, { useEffect, useState } from 'react'
import stockApiClient, { StockPrice } from '../../apiclients/StockApiClient.ts'
import { StockList } from './StockList.tsx'
import { formatDate, getTargetDate, parseDateString } from "../../utils/dates.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function StockListPage() {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const dateString = searchParams.get('date')
    const [date, setDate] = useState(getTargetDate(parseDateString(dateString)))
    const [stockPrices, setStockPrices] = useState<StockPrice[]>([])
    const [loading, setLoading] = useState(false)

    const handleChangeDate = (date: Date) => {
        setDate(date)
        navigate(`/stocks?date=${formatDate(date)}`)
    }

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => stockApiClient.getStockWatchlist(date))
            .then(data => setStockPrices(data.map(stock => ({ ...stock, key: stock.ticker }))))
            .finally(() => setLoading(false))
    }, [date])

    return (
        <StockList
            date={date}
            onChangeDate={handleChangeDate}
            stockPrices={stockPrices}
            loading={loading}
        />
    )
}
